import request from '@/utils/request'

//我的虚拟仿真成绩列表
export function getmyvrstudylist(params) {
    return request({
        url: '/portal/vr-study/get-my-list',
        method: 'GET',
        params
    })
}

//虚拟仿真成绩列表
export function getvrstudylist(params) {
    return request({
        url: '/portal/vr-study/get-list',
        method: 'GET',
        params
    })
}

//虚拟仿真步骤成绩
export function getStepList(params) {
    return request({
        url: '/portal/vr-study/get-step-list',
        method: 'GET',
        params
    })
}