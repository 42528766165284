import request from '@/utils/request'

//获取实验时长统计
export function getvrtimetrend(params) {
    return request({
        url: '/portal/vr-data/vr-time-trend',
        method: 'GET',
        params
    })
}

//获取实验次数统计
export function getvrcounttrend(params) {
    return request({
        url: '/portal/vr-data/vr-count-trend',
        method: 'GET',
        params
    })
}