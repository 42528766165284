import request from '@/utils/request'

//获取用户信息
export function setuserfavorite(data) {
    return request({
        url: '/portal/user-favorite/set',
        method: 'POST',
        data
    })
}

//获取用户个人收藏列表
export function getuserfavoritelist(params) {
    return request({
        url: '/portal/user-favorite/get-list',
        method: 'GET',
        params
    })
}